import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Text} from '../com/util/text/Text'
import {s, colors} from 'lib/styles'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {ToggleButton as ToggleButtonUtil} from 'view/com/util/forms/ToggleButton'
import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {ViewHeader} from 'view/com/util/ViewHeader'
import {CenteredView} from 'view/com/util/Views'
import {Trans, msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {
  LikeButtonIconType,
  useSetUIPreferencesMutation,
} from '#/state/queries/preferences'
import {RadioGroup} from '../com/util/forms/RadioGroup'
import {Heart2_Filled_Stroke2_Corner0_Rounded as HeartIconFilled} from '#/components/icons/Heart2'
import {Star_Filled_Corner0_Rounded as StarIconFilled} from '#/components/icons/Star'
import {useMemo} from 'react'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'PreferencesUI'>
export function PreferencesUI({navigation}: Props) {
  const pal = usePalette('default')
  const {_} = useLingui()
  const {isTabletOrDesktop} = useWebMediaQueries()
  const {mutate: setUIPref, variables} = useSetUIPreferencesMutation()

  const showScrollToTopButton = variables?.showScrollToTopButton ?? true
  const likeButtonIcon = useMemo(() => variables?.likeButtonIcon, [variables])
  const showCounts = variables?.showCounts ?? true

  return (
    <CenteredView
      testID="preferencesHomeFeedScreen"
      style={[
        pal.view,
        pal.border,
        styles.container,
        isTabletOrDesktop && styles.desktopContainer,
      ]}>
      <ViewHeader title={_(msg`UI Preferences`)} showOnDesktop />
      <View
        style={[
          styles.titleSection,
          isTabletOrDesktop && {paddingTop: 20, paddingBottom: 20},
        ]}>
        <Text type="xl" style={[pal.textLight, styles.description]}>
          <Trans>Setting for buttons and menus UI.</Trans>
        </Text>
      </View>

      <ScrollView>
        <View style={styles.cardsContainer}>
          <View style={[pal.viewLight, styles.card]}>
            <Text type="title-sm" style={[pal.text, s.pb5]}>
              <Trans>Show ScrollToTop Button</Trans>
            </Text>
            <Text style={[pal.text, s.pb10]}>
              <Trans>
                Set this setting to "No" to hide scrollToTop Button.
              </Trans>
            </Text>
            <ToggleButtonUtil
              testID="toggleShowScrollToTopBtn"
              type="default-light"
              label={showScrollToTopButton ? _(msg`Yes`) : _(msg`No`)}
              isSelected={showScrollToTopButton}
              onPress={() =>
                setUIPref({
                  showScrollToTopButton: !showScrollToTopButton,
                })
              }
            />
          </View>
          {likeButtonIcon && (
            <View style={[pal.viewLight, styles.card]}>
              <Text type="title-sm" style={[pal.text, s.pb5]}>
                <Trans>Like Button Icon</Trans>
              </Text>
              <Text style={[pal.text, s.pb10]}>
                <Trans>You can change the like button icon.</Trans>
              </Text>
              <View style={[pal.view, {borderRadius: 8, paddingVertical: 6}]}>
                <RadioGroup
                  type="default-light"
                  items={[
                    {
                      key: 'like',
                      label: <HeartIconFilled style={s.likeColor} />,
                    },
                    {
                      key: 'favorite',
                      label: <StarIconFilled style={s.favoriteColor} />,
                    },
                  ]}
                  onSelect={key =>
                    setUIPref({likeButtonIcon: key as LikeButtonIconType})
                  }
                  initialSelection={likeButtonIcon}
                />
              </View>
            </View>
          )}
          <View style={[pal.viewLight, styles.card]}>
            <Text type="title-sm" style={[pal.text, s.pb5]}>
              <Trans>Show RePost and Like Counts</Trans>
            </Text>
            <Text style={[pal.text, s.pb10]}>
              <Trans>
                Set this setting to "No" to hide RePost and Like counts.
              </Trans>
            </Text>
            <ToggleButtonUtil
              testID="toggleShowCountsBtn"
              type="default-light"
              label={showCounts ? _(msg`Yes`) : _(msg`No`)}
              isSelected={showCounts}
              onPress={() =>
                setUIPref({
                  showCounts: !showCounts,
                })
              }
            />
          </View>
        </View>
      </ScrollView>

      <View
        style={[
          styles.btnContainer,
          !isTabletOrDesktop && {borderTopWidth: 1, paddingHorizontal: 20},
          pal.border,
        ]}>
        <TouchableOpacity
          testID="confirmBtn"
          onPress={() => {
            navigation.canGoBack()
              ? navigation.goBack()
              : navigation.navigate('Settings')
          }}
          style={[styles.btn, isTabletOrDesktop && styles.btnDesktop]}
          accessibilityRole="button"
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint="">
          <Text style={[s.white, s.bold, s.f18]}>
            <Trans>Done</Trans>
          </Text>
        </TouchableOpacity>
      </View>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 90,
  },
  desktopContainer: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingBottom: 40,
  },
  titleSection: {
    paddingBottom: 30,
  },
  title: {
    textAlign: 'center',
    marginBottom: 5,
  },
  description: {
    textAlign: 'center',
    paddingHorizontal: 32,
  },
  cardsContainer: {
    paddingHorizontal: 20,
  },
  card: {
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    padding: 14,
    backgroundColor: colors.blue3,
  },
  btnDesktop: {
    marginHorizontal: 'auto',
    paddingHorizontal: 80,
  },
  btnContainer: {
    paddingTop: 20,
  },
  dimmed: {
    opacity: 0.3,
  },
})
